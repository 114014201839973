<template>
  <cw-page
    icon="check"
    class="cw-business-loan-complete"
  >
    <template #hero-title>
      <translate>
        Thank you for starting a Saldo loan application
      </translate>
    </template>

    <v-row>
      <v-col
        class="
          cw-border-container
          cw-border-container--primary
        "
        cols="12"
        md="8"
      >
        <p>
          <translate>
            The merchant's part of the loan application is now complete and a link will be sent to
            the customer's email with which they can continue the application. After a positive loan
            decision the granted amount is paid into the merchant’s account.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CwSmePosMerchantComplete',

  created() {
    this.setPurchase(false);
  },

  methods: {
    ...mapActions({
      setPurchase: 'application/setPurchase',
    }),
  },
};
</script>
